import {
  useCachedPost,
  useSecureSessionPost,
} from '@/composables/dataFetching/genericFetchers';
import { useSortingOptions } from './composables/useSortingOptions';
import { scrollToElement } from '~/helpers/scroll';
import { handleLoadingError } from '~/utils/handleLoadingError';

export interface FilterOption {
  //use values for dynamic filter content (e.g. count of each value)
  values?: {
    id: string;
    label: string;
    isAvailable?: boolean;
    availableSortingOptions?: string[];
    data?: Record<string, any>;
  }[];
  //use data only for static filter content (e.g. the filter label)
  data?: Record<string, any>;
  type?: string;
}

export type FilterOptions<F extends string = string> = Partial<
  Record<F, FilterOption>
>;

export interface AdvancedListConfig<
  P = Record<string, any>,
  S extends string = string,
  F extends string = string,
> {
  id: string;
  entriesPerPage: number;
  params: P;
  apiPath: string;
  /**
   * at least one sorting option needs to be provided, for the initial sorting
   */
  sortingOptions: S[];
  filterOptions?: FilterOptions<F>;
  useSessionPost?: boolean;
}

export interface AdvancedListInitialData<T = any> {
  entries?: T[];
  entryCount?: number;
}

export type Filters<F extends string = string> = Partial<Record<F, any>>;

/**
 * @Generic_S enum of the sorting options
 * @Generic_F enum of the filter keys
 * @Generic_P Type of the load entries request params
 *
 * type for the request body to load new data
 */
export type AdvancedListLoadBody<
  S = string,
  F extends string = string,
  P = Record<string, any>,
> = {
  limit: number;
  offset: number;
  orderBy?: S;
  initial?: boolean;
} & Filters<F> &
  P;

/**
 * @Generic_T Type of the entries
 * @Generic_F enum of the filter keys
 *
 * type for the response when loading data
 */
export interface AdvancedListLoadResult<
  T = any,
  F extends string = string,
  S extends string = string,
> {
  entries: T[];
  entryCount: number;
  filterOptions: FilterOptions<F>;
  // if there was an error while loading from the gateway
  error?: boolean;
  sortingOptions?: S[];
}

/**
 * @Generic_T Type of the entries
 * @Generit_P Type of the load entries request params
 * @Generic_S enum of the sorting options
 * @Generic_F enum of the filter keys
 */
export async function useAdvancedList<
  T = any,
  P = Record<string, any>,
  S extends string = string,
  F extends string = string,
>(
  reactiveConfig: Ref<AdvancedListConfig<P, S, F>>,
  initialData?: AdvancedListInitialData<T>,
  initialFilters?: Filters<F>,
) {
  const { t } = useTrans();
  const error = ref(false);
  let initial: Partial<AdvancedListLoadResult<T, F, S>> = initialData;
  let data: AdvancedListLoadResult<T, F, S> | undefined;
  const config = reactiveConfig.value;

  if (!initial) {
    initial = await load<T, P, S, F>(
      reactiveConfig.value,
      config.entriesPerPage,
      0,
      config.sortingOptions[0] as S,
      initialFilters ?? {},
      true,
    );
    error.value = initial.error;
  }

  const [orderBy, sortingOptions] = useSortingOptions<S>(
    initial.sortingOptions ?? config.sortingOptions ?? [],
    t,
    config.filterOptions ?? initial?.filterOptions ?? {},
    initialFilters,
  );

  if (
    !initialData &&
    ((initialFilters && !config.filterOptions) ||
      (config.sortingOptions[0] as S) !== orderBy.value)
  ) {
    data = await load<T, P, S, F>(
      reactiveConfig.value,
      config.entriesPerPage,
      0,
      orderBy.value,
      initialFilters ?? {},
    );
    error.value = data.error;
  }

  const isLoading = ref(false);
  const entries = ref<T[]>(data?.entries ?? initial.entries ?? []) as Ref<T[]>;

  const pages = ref(
    getPages(data?.entryCount ?? initial.entryCount, config.entriesPerPage),
  );
  const page = ref(1);
  const entryCount = ref(data?.entryCount ?? initial.entryCount);

  const initialStaticFilterOptions =
    config.filterOptions ?? initial?.filterOptions ?? {};
  const initialDynamicFilterOptions =
    config.filterOptions && initial?.filterOptions
      ? initial.filterOptions
      : data?.filterOptions;

  const filterOptions = ref<FilterOptions<F>>(
    buildFilterOptions(initialStaticFilterOptions, initialDynamicFilterOptions),
  ) as Ref<FilterOptions<F>>;

  const filters = reactive<Filters<F>>(
    initialFilters ? { ...initialFilters } : {},
  ) as Filters<F>;

  const combinedSettings = computed(() => ({
    ...filters,
    orderBy: orderBy.value,
  }));

  let loadingIndex = 0;

  async function reInitialize() {
    initial = initialData;
    if (!initial) {
      initial = await load<T, P, S, F>(
        reactiveConfig.value,
        config.entriesPerPage,
        0,
        orderBy.value,
        initialFilters ?? {},
        true,
      );
      error.value = initial.error;
    }

    if (!initialData && initialFilters && !config.filterOptions) {
      data = await load<T, P, S, F>(
        reactiveConfig.value,
        config.entriesPerPage,
        0,
        orderBy.value,
        initialFilters ?? {},
      );
      error.value = data.error;
    }

    entries.value = data?.entries ?? initial.entries ?? [];

    pages.value = getPages(
      data?.entryCount ?? initial.entryCount,
      config.entriesPerPage,
    );
    page.value = 1;
    entryCount.value = data?.entryCount ?? initial.entryCount;

    const initialStaticFilterOptions =
      config.filterOptions ?? initial?.filterOptions ?? {};
    const initialDynamicFilterOptions =
      config.filterOptions && initial?.filterOptions
        ? initial.filterOptions
        : data?.filterOptions;
    filterOptions.value = buildFilterOptions(
      initialStaticFilterOptions,
      initialDynamicFilterOptions,
    );

    resetFilters();
  }

  async function scopedLoad(
    scopedOffset: number,
    scopedOrderBy: S,
    scopedFilters: Filters<F>,
  ) {
    loadingIndex++;
    const id = loadingIndex;
    const data = await load<T, P, S, F>(
      reactiveConfig.value,
      reactiveConfig.value.entriesPerPage,
      scopedOffset,
      scopedOrderBy,
      scopedFilters,
    );
    error.value = data.error;
    if (id !== loadingIndex) return false;

    return data;
  }

  const applyChanges = (
    newEntries: T[],
    newEntryCount: number,
    newFilterOptions: FilterOptions<F>,
  ) => {
    // update entries
    entries.value = newEntries;

    //if the entry count changed recalculate the amout of pages and set page to 1
    if (entryCount.value !== newEntryCount) {
      pages.value = getPages(newEntryCount, config.entriesPerPage);
      page.value = 1;
    }

    //update entry count
    entryCount.value = newEntryCount;

    //update filter options
    filterOptions.value = buildFilterOptions(
      initialStaticFilterOptions,
      newFilterOptions,
    );
  };

  async function reload() {
    isLoading.value = true;

    // load data for first page
    const data = await scopedLoad(0, orderBy.value, filters);
    if (data) {
      // applyChanges
      applyChanges(data.entries, data.entryCount, data.filterOptions);
      // set page to first
      page.value = 1;
      isLoading.value = false;
    }
  }

  //on Sort change
  watch(
    () => orderBy.value,
    async (nv) => {
      isLoading.value = true;

      //load data for first page
      const data = await scopedLoad(0, nv, filters);
      if (data) {
        ///applyChanges
        applyChanges(data.entries, data.entryCount, data.filterOptions);
        // set first page
        page.value = 1;
        isLoading.value = false;
      }
    },
  );

  // on paginate
  watch(
    () => page.value,
    async (nv) => {
      isLoading.value = true;

      const element = document.getElementById(config.id);
      scrollToElement(element, 90);

      // load data for next page
      const data = await scopedLoad(
        (nv - 1) * config.entriesPerPage,
        orderBy.value,
        filters,
      );
      if (data) {
        // applyChanges
        applyChanges(data.entries, data.entryCount, data.filterOptions);
        isLoading.value = false;
      }
    },
  );

  // on filter change
  watch(filters, async (nv) => {
    isLoading.value = true;

    // update sorting options because they can vary depending on the filter
    const [newOrderBy, newSortingOptions] = useSortingOptions(
      initial.sortingOptions ?? config.sortingOptions,
      t,
      config.filterOptions ?? initial?.filterOptions ?? {},
      filters,
    );

    orderBy.value =
      newOrderBy.value === orderBy.value ? newOrderBy.value : orderBy.value;
    sortingOptions.value = newSortingOptions.value;

    // load data for first page
    const data = await scopedLoad(0, orderBy.value, nv);
    if (data) {
      // applyChanges
      applyChanges(data.entries, data.entryCount, data.filterOptions);
      // set page to first
      page.value = 1;
      isLoading.value = false;
    }
  });

  function resetFilters(ping = false) {
    if (ping) sendResetFiltersPing();

    Object.keys(filters).forEach((key) => {
      delete filters[key as F];
    });
    if (initialFilters) {
      Object.keys(initialFilters).forEach((key) => {
        filters[key as F] = initialFilters[key as F];
      });
    }

    const [initialOrderBy, initialSortingOptions] = useSortingOptions<S>(
      initial.sortingOptions ?? config.sortingOptions ?? [],
      t,
      config.filterOptions ?? initial?.filterOptions ?? {},
      initialFilters,
    );
    orderBy.value = initialOrderBy.value;
    sortingOptions.value = initialSortingOptions.value;
  }

  /**
   * use this to check if the resetFilters has been triggered
   */
  const resetFiltersPing = ref(false);
  function sendResetFiltersPing() {
    resetFiltersPing.value = true;
    setTimeout(() => {
      resetFiltersPing.value = false;
    }, 5);
  }

  return {
    id: config.id,
    isLoading,
    entries,
    pages,
    page,
    entryCount,
    sortingOptions,
    orderBy,
    filterOptions,
    filters,
    combinedSettings,
    error,
    resetFiltersPing,
    sendResetFiltersPing,
    resetFilters,
    reload,
    reInitialize,
  };
}

async function load<
  T = any,
  P = Record<string, any>,
  S extends string = string,
  F extends string = string,
>(
  config: AdvancedListConfig<P, S, F>,
  limit: number,
  offset: number,
  orderBy: S,
  filters: Filters<F>,
  initial = false,
): Promise<AdvancedListLoadResult<T, F, S>> {
  try {
    const rawOptions: AdvancedListLoadBody<S, F, P> = {
      ...config.params,
      ...filters,
      orderBy,
      limit,
      offset,
      initial,
    };

    const site = useSiteIdent();
    if (!config.useSessionPost) {
      const { data } = await useCachedPost(
        `/api/${site}/${config.apiPath}`,
        rawOptions,
        {
          blockId: config.id,
        },
      );

      return {
        entries: data.value?.entries,
        entryCount: data.value?.entryCount,
        filterOptions: data.value?.filterOptions,
        sortingOptions: data.value?.sortingOptions,
        error: false,
      };
    } else {
      const data = await useSecureSessionPost(
        `/api/${site}/${config.apiPath}`,
        rawOptions,
      );

      return {
        entries: data?.entries,
        entryCount: data?.entryCount,
        filterOptions: data?.filterOptions,
        sortingOptions: data?.sortingOptions,
        error: false,
      };
    }
  } catch (e) {
    handleLoadingError(e);
    return {
      entries: [],
      entryCount: 0,
      filterOptions: {},
      error: true,
    };
  }
}

/**
 * build the filter options form statis and dynamic filter options
 * @param staticFilterOptions the static filter options. required.
 *  the staic filter options are used to build the structure of the filter options.
 *  all values of the static filter options will appear in the filters in the list.
 * @param dynamicFilterOptions the dynamic filter options. optional.
 *  the dynamic filter options are used to set the isAvailable flag and if set the data of the individual filter options.
 *  if there is a value in the staic filter options and not in the dynamic filter options the isAvailable flag will be set to false.
 *
 * edgecase: if there are no dynamic filter options (example: when initzializing the list) the isAvailable
 * flag will be set to true and the data from the static filter options will taken.
 *
 * @returns the filter options with the isAvailable flag and the data set
 */
function buildFilterOptions<F extends string = string>(
  staticFilterOptions: FilterOptions<F>,
  dynamicFilterOptions?: FilterOptions<F>,
): FilterOptions<F> {
  const hasDynamicFilterOptions = !!dynamicFilterOptions;

  const options = Object.entries<FilterOption>(staticFilterOptions).reduce(
    (acc, [key, value]) => {
      acc[key as F] = {
        ...value,
        values:
          value.values?.map((x) => {
            const dynamicOption = hasDynamicFilterOptions
              ? dynamicFilterOptions?.[key as F]?.values.find(
                  (v) => v.id === x.id,
                )
              : undefined;
            return {
              ...x,
              isAvailable: hasDynamicFilterOptions ? !!dynamicOption : true,
              data:
                dynamicOption?.data ??
                (hasDynamicFilterOptions ? undefined : x.data),
            };
          }) ?? [],
      };

      return acc;
    },
    {} as FilterOptions<F>,
  );

  if (dynamicFilterOptions) {
    Object.entries<FilterOption>(dynamicFilterOptions).forEach(
      ([key, filterOption]) => {
        if (options[key as F]) {
          filterOption.values?.forEach((value) => {
            const option = options[key as F].values?.find(
              (x) => x.id === value.id,
            );
            if (!option) {
              options[key as F].values?.push({
                ...value,
                isAvailable: true,
                data: value.data,
              });
            }
          });
        }
      },
    );
  }

  return options;
}

function getPages(entryCount: number, entriesPerPage: number) {
  return Math.ceil((entryCount ?? 0) / entriesPerPage);
}

export function useListReloadPing() {
  const reloadPing = ref(false);

  function sendReloadPing() {
    reloadPing.value = true;
    setTimeout(() => {
      reloadPing.value = false;
    }, 1);
  }

  return {
    reloadPing,
    sendReloadPing,
  };
}
