import type { DropdownOption } from '../components/dropdown/useDropdown';
import type {
  FilterOptions,
  Filters,
} from '~/components/components/advancedList/useAdvancedList';

export function useSortingOptions<T = string>(
  rawOptions: T[],
  t: ReturnType<typeof useTrans>['t'],
  filterOptions: FilterOptions,
  initialFilters: Filters,
): [Ref<T>, Ref<DropdownOption[]>] {
  const options = rawOptions.map((e) => {
    const token = `sorting.${e}`;
    const label = t(token);
    return {
      isAvailable: checkIfSortingOptionIsAvailable(
        filterOptions,
        initialFilters,
        e as string,
      ),
      key: e as string,
      label: label === token ? (e as string) : label,
    };
  });

  const value = ref(options.find((e) => e.isAvailable)?.key) as Ref<T>;
  return [value, ref(options)];
}

function checkIfSortingOptionIsAvailable(
  filterOptions: FilterOptions,
  filters: Filters,
  value: string,
) {
  if (!filters || !Object.keys(filters).length) {
    return true;
  }

  let isOptionAvailable = false;
  Object.entries(filters).forEach(([k, v]) => {
    const filterOption = filterOptions[k]?.values.find(
      (option) => option?.id === v,
    );
    if (
      filterOption &&
      filterOption.hasOwnProperty('availableSortingOptions')
    ) {
      isOptionAvailable = filterOption.availableSortingOptions.includes(value);
    } else {
      isOptionAvailable = true;
    }
  });

  return isOptionAvailable;
}
